import { useRouter } from 'next/router'
import { Button } from '@unholster/seisveinte'
import { Checkbox, Form, Input } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { ClientLogoSvg } from 'components/Images'
import { CenteredLoading } from 'components/Loading'
import { colors, spaces } from 'global-parameters'

const { FilledButton, OutlineButton } = Button

export default () => {
  const login = useStoreActions((actions) => actions.login)
  const { contactMail } = useStoreState((state) => state.config)
  const router = useRouter()
  const sesameToken = router.query.sesame
  const [formError, setError] = useState('false')
  const [errorMessage, setErrorMessage] = useState()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values) => {
    const { username } = values
    const { password } = values

    setLoading(true)
    const error = await login({ password, username })
    setLoading(false)
    if (error) {
      if (error.message.includes('Failed to fetch')) {
        setErrorMessage(
          <>
            Error de conexión.
            <br />
            Por favor intente más tarde
          </>,
        )
      } else if (error.message.includes('Bad credentials')) {
        setErrorMessage('Usuario o contraseña inválido')
      } else if (error.message.includes('You do not have a user associated')) {
        setErrorMessage(
          <>
            No tienes un usuario asociado en la plataforma.
            Por favor contacta a
            {' '}
            <a href={`mailto:${contactMail}`}>{contactMail}</a>
          </>,
        )
      } else {
        setErrorMessage('Ocurrió un error. Inténtelo más tarde.')
      }
      setError('true')
    } else {
      router.push('/')
    }
  }

  useEffect(() => {
    if (sesameToken) {
      login({ sesameToken }).then((err) => {
        if (err) {
          setError('true')
        } else {
          router.push('/')
        }
      })
    }
  }, [login, router, sesameToken])

  return (
    <LoginComponentWrapper>
      <ClientLogoWrapper>
        <ClientLogoSvg height={92} width={190} />
      </ClientLogoWrapper>
      {
        loading
          ? <StyledLoading />
          : null
      }
      <TextWrapper>
        <Title>Bienvenido</Title>
        <Subtitle>
          Para ingresar a la plataforma
          <br />
          ingrese sus credenciales
        </Subtitle>
      </TextWrapper>
      <StyledForm
        onFinish={handleSubmit}
      >
        <StyledForm.Item
          error={formError}
          name="username"
          onChange={() => setError('false')}
          rules={[
            {
              message: 'Ingrese usuario',
              required: true,
            },
          ]}
        >
          <Input
            placeholder="Usuario"
          />
        </StyledForm.Item>
        <StyledForm.Item
          error={formError}
          name="password"
          onChange={() => setError('false')}
          rules={[
            {
              message: 'Ingrese contraseña',
              required: true,
            },
          ]}
        >
          <Input.Password placeholder="Contraseña" />
        </StyledForm.Item>
        {errorMessage && <Alert>{errorMessage}</Alert>}
        {/* TODO: Not supported workflow yet */}
        {/* <CheckboxWrapper>Recordar contraseña</CheckboxWrapper> */}
        <LoginButton htmlType="submit" type="primary">Ingresar</LoginButton>
        {/* TODO: Not supported workflow yet */}
        {/* <ForgotPassword>¿Olvidó su contraseña?</ForgotPassword> */}
      </StyledForm>
    </LoginComponentWrapper>
  )
}

export const Alert = styled.div`
  color: ${colors.redScore};
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
  a {
    font-weight: 600;
    text-decoration-line: underline;

    :hover {
      color: ${colors.red};
    }
  }
`

export const LoginComponentWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.white};
    box-shadow: 1px 1px 5px ${colors.fontPrimary};
    padding: 40px 40px;
    max-width: 320px;
    border-radius: 5px;
    width: 100%;
    > * + * {
      margin-top: ${spaces.l};
    }

    @media (max-width: 375px) {
      box-shadow: none;
    }
`

export const ClientLogoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextWrapper = styled.div`
  text-align: center;
`

export const Title = styled.div`
  font-size: 24px;
  color: ${colors.black};
  font-weight: 700;
  margin-bottom: 15px;
`

export const Subtitle = styled.div``

export const CheckboxWrapper = styled(Checkbox)`
  width: 100%;

  .ant-checkbox + span {
    padding-left: 14px;
    font-size: 12px;
  }
`

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;

  .ant-row, .ant-form-item {
    width: 100%;
  }
`

export const LoginButton = styled(FilledButton)`
  width: 100%;
  justify-content: center;
  padding: 5px 0;
  font-size: 14px;
  margin: 20px 0 10px 0;
`

export const ForgotPassword = styled(OutlineButton)`
  border: none;
  background: transparent;
  box-shadow: none;
  font-size: 12px;
`

const StyledLoading = styled(CenteredLoading)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0.9;
  margin-top: 0;

  .anticon {
    font-size: 80px !important;
    opacity: 1;
  }
`
