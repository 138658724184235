import Image from 'next/image'

export const ClientLogoSvg = (props) => <Image alt="clientLogo" height={30} src="/assets/clientLogo.svg" width={30} {...props} />
export const UnholsterLogoSvg = (props) => <Image alt="unholsterLogo" height={30} src="/assets/unholsterLogo.svg" width={30} {...props} />
export const EmptyTableSvg = (props) => <Image alt="emptytableImage" height={417} src="/assets/emptytable.svg" width={417} {...props} />
export const NoDataSvg = (props) => <Image alt="nodataImage" height={357} src="/assets/noData.svg" width={485} {...props} />
export const NoDataWarningSvg = (props) => <Image alt="nodatawarningImage" height={177} src="/assets/noDataWarning.svg" width={290} {...props} />
export const Error500Svg = (props) => <Image alt="error500Image" height={300} src="/assets/error500.svg" width={509} {...props} />
export const ProcesoInConfigurationSvg = (props) => (
  <Image
    alt="procesoInConfigurationImage"
    height={317}
    src="/assets/procesoInConfiguration.svg"
    width={472}
    {...props}
  />
)
