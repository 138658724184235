import { useRouter } from 'next/router'
import { useStoreState } from 'easy-peasy'
import styled from 'styled-components'

import { CenteredLoading } from 'components/Loading'
import Login from 'components/pages/login/Login'
import { colors } from 'global-parameters'

export default () => {
  const loggedIn = useStoreState((state) => state.loggedIn)
  const router = useRouter()

  if (loggedIn && !router.query.sesame) {
    router.push('/')
    return <CenteredLoading />
  }

  return (
    <LoginComponentWrapper>
      <Login />
    </LoginComponentWrapper>
  )
}

export const LoginComponentWrapper = styled.div`
  display: flex;
  background-color: ${colors.backgroundPrimary};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
